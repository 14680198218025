<template>
  <section class="content">
    <div class="btn-box">
      <el-button type="primary" size="mini" @click="add">新增奖池</el-button> 
    </div>
    <el-table
        v-loading="loading"
        :data="list"
        border>
        <el-table-column label="id" prop="id" width="50"></el-table-column>
        <el-table-column label="开始时间">
          <template slot-scope="scope">
            {{scope.row.start_time | dateTime}}
          </template>
</el-table-column>
<el-table-column label="结束时间">
    <template slot-scope="scope">
            {{scope.row.end_time | dateTime}}
          </template>
</el-table-column>
<el-table-column label="关联商品数量" prop="item_num"></el-table-column>
<el-table-column label="操作" width="200">
    <template slot-scope="scope">
    <el-button type="primary" size="mini" @click="check(scope.row.id)" >查看</el-button>
    <el-button type="warning" size="mini" @click="relevance(scope.row.id)">关联商品</el-button>
  </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange"></el-pagination>
<!-- 关联商品 -->
<el-dialog title="关联商品" :visible.sync="shopDialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="商品ID">
            <el-input v-model="item_id" placeholder="请输入商品ID"></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="shopDialog = false">取 消</el-button>
        <el-button type="primary" @click="relevanceItem">确 定</el-button>
    </div>
</el-dialog>
<!-- 新增奖池 -->
<el-dialog title="添加奖池" :visible.sync="addAssociation" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="开始时间">
            <el-date-picker v-model="start_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="开始时间">
            </el-date-picker>
        </el-form-item>
        <el-form-item label="结束时间">
            <el-date-picker v-model="end_time" type="datetime" value-format="yyyy-MM-dd HH:mm:ss" placeholder="结束时间">
            </el-date-picker>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="addAssociation = false">取 消</el-button>
        <el-button type="primary" @click="postDoubleElevenActList">确 定</el-button>
    </div>
</el-dialog>

</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: "DoubleEleven",
        data() {
            return {
                list: [],

                loading: true,
                total: 0,
                page: 1,
                pagesize: 10,

                shopDialog: false,
                addAssociation: false,

                start_time: '',
                end_time: '',

                community_id: '',
                item_id: ''
            }
        },
        filters: {
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getDoubleElevenActList()
        },
        methods: {
            getDoubleElevenActList(page, size) {
                api.getDoubleElevenActList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize
                }, res => {
                    this.list = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            sizeChange(val) {
                this.pagesize = val
                this.getDoubleElevenActList()
            },
            currentChange(val) {
                this.page = val
                this.getDoubleElevenActList()
            },
            add() {
                this.start_time = ''
                this.end_time = ''
                this.addAssociation = true
            },
            postDoubleElevenActList() {
                api.addDoubleElevenActList({
                    start_time: this.start_time,
                    end_time: this.end_time,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.addAssociation = false
                    this.getDoubleElevenActList()
                })
            },
            check(id) {
                this.$router.push({
                    name: 'DoubleElevenData',
                    query: {
                        id: id
                    }
                })
            },
            relevance(id) {
                this.community_id = id
                this.item_id = ''
                this.shopDialog = true
            },
            relevanceItem() {
                api.addDoubleElevenActShop({
                    community_id: this.community_id,
                    item_id: this.item_id,
                }, res => {
                    this.$message({
                        type: 'success',
                        message: res.msg
                    })
                    this.shopDialog = false
                    this.getDoubleElevenActList()
                })
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .el-form-item {
        text-align: left;
    }
</style>